:root {
  --HIGHLIGHT_COLOR: lime;
}

h1 {
  margin: 0;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  min-height: calc(100vh - 130px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.overflow {
  /* overflow-x: scroll; */
  overflow-y: hidden;
  white-space: nowrap;
}

div.weather-cell {
  width: 100px;
  height: 100px;
  display: inline-block;
}

img.weather {
  width: 100%;
  max-width: 150px;
}

img.em-1 {
  height: 1.5em;
}
img.em-2 {
  height: 2em;
}
img.em-3 {
  height: 3em;
}
img.em-4 {
  height: 4em;
}
img.em-5 {
  height: 5em;
}

hr.narrow {
  margin: 2px;
}

svg rect.stats-box {
  fill: lightgray;
  stroke: black;
}

.icon {
  width: 1em;
  display: inline;
  position: relative;
  /* bottom: 0.15em; */
}

.feedback-btn {
  padding: 0.5rem;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 15px;
  bottom: 15px;
  transition: all 0.2s ease 0s;
  z-index: 99999;
}

.search-results {
  position: relative;
  z-index: 99999 !important;
}

/* Leaflet pulsating icon */
@keyframes fade {
  from {
    opacity: 0.7;
  }
}

.blinking {
  animation: fade 1s infinite alternate;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0 0;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
